import { Box } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from "react-beautiful-dnd";
import PropBadge from "../../../../../Badges/PropBadge";
import {useAppDispatch} from "../../../../../../hooks/redux";
import {setRequestStatistics} from "../../../../../../store/reducer/statisticsReducer";

interface BadgesProps {
    filterGroup: any;
    removeBadge: (keyField: Record<string, any>) => void;
    keyField: string;
    request: any;
    field: "filters" | "metrics" | "groups";
    mappingList?: any;
}

const Badges = ({ filterGroup, removeBadge, keyField, request, field, mappingList = {} }: BadgesProps) => {
    const dispatch = useAppDispatch()
    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;
        // Если элемент остался на том же месте, ничего не делаем
        if (source.index === destination.index) {
            return;
        }

        const updatedFilters = [...filterGroup]
        const [movedBadge] = updatedFilters.splice(source.index, 1);
        updatedFilters.splice(destination.index, 0, movedBadge);
        dispatch(setRequestStatistics({field, updateDataRequest: updatedFilters}))
    };

    function handleCloseBadge(field: Record<string, any>) {
        removeBadge(field);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="badges" direction="horizontal">
                {(provided: DroppableProvided) => (
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                            display: "flex",
                            ml: "12px",
                            alignItems: "center",
                            gap: "12px",
                            flexWrap: "wrap",
                            p: "9px 0",
                        }}
                    >
                        {request[field].length > 0 &&
                            request[field]
                                .filter(
                                    (filterEntity: { [x: string]: any }) =>
                                        Object.values(filterEntity).every(value => value !== null && value !== "" && value !== undefined) || filterEntity.hint === ""
                                )
                                .map((filterEntity: Record<string, any>, index: number) => (
                                    <Draggable key={filterEntity[keyField]} draggableId={filterEntity[keyField]} index={index}>
                                        {(dragProvided: DraggableProvided) => (
                                            <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                                                <PropBadge id={filterEntity[keyField]} filterEntity={filterEntity} close={handleCloseBadge}>
                                                    {filterEntity.name || `${mappingList[filterEntity.key]?.column}=${filterEntity.value}` || `${filterEntity.label}=${filterEntity.value}`}{" "}
                                                </PropBadge>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};
export default Badges;
