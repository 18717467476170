import React from "react";
import "./index.css"
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate()
    return(
        <div className="not_found_block">
            <span className="main_text">404</span>
            <div className="description_cont">
                <span className="description_text_1">Page not found</span>
                <span className="description_text_2">The page you are looking for may have been moved, deleted, or possibly never existed.</span>
                <Button variant="contained" onClick={() => navigate("/")}>Home page</Button>
            </div>
        </div>
    )
}

export default NotFound