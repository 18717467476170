import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type StateAlertType = {
    openAlert?: boolean
    typeAlert?: "error" | "info" | "success" | "warning"
    messageAlert?: string
}

const initialState: StateAlertType  = {
    openAlert: false,
    typeAlert: "success",
    messageAlert: "",
}

const AlertSlice = createSlice({
    name: "AlertSlice",
    initialState,
    reducers: {
        setAlertData: (state, action: PayloadAction<StateAlertType>) => {
            const {openAlert, typeAlert, messageAlert} = action.payload
            Object.assign(state, {openAlert, typeAlert, messageAlert})
        }
    }
})

export const { setAlertData } = AlertSlice.actions

export default AlertSlice.reducer