import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import dayjs, {Dayjs} from "dayjs";
import {generateId} from "../../services/helpers";

export type DayJSType = string | number | dayjs.Dayjs | Date | null | undefined

interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}

type RequestDataType = {
    periods: {
        date_from: DayJSType
        date_to:  DayJSType
        currency: string
        periodHotKey: any
    }
}

const initialState: RequestDataType = {
    periods: {
        date_from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        date_to: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        currency: "",
        periodHotKey: null
    },
}

const periodTableSlice = createSlice({
    name: "periodTableSlice",
    initialState,
    reducers: {
        setPeriods: (state, action: PayloadAction<{date_from?: DayJSType, date_to?: DayJSType, periodType?: string}>) => {
            const { date_from, date_to, periodType} = action.payload
            switch (periodType) {
                case "date_from":
                    state.periods.date_from = dayjs(date_from).format("YYYY-MM-DD")
                    break
                case "date_to":
                    state.periods.date_to = dayjs(date_to).format("YYYY-MM-DD")
                    break
                default: Object.assign(state.periods, { date_from, date_to})
            }

        },
    }
})

export const {
    setPeriods,
} = periodTableSlice.actions

export default periodTableSlice.reducer