import {FC, ReactElement} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";
import {useAuthMeQuery} from "../../api/authApi";

type ProtectedRouteType = {
    permission: string,
    redirectPath?: string,
    children?: ReactElement
}

const ProtectedRoute: FC<ProtectedRouteType> = ({
    permission,
    redirectPath = "/permission-denied",
    children=undefined,
}) => {
    const {data: me} = useAuthMeQuery("")
    if (me && !me.permissions.includes(permission)) {
        return <Navigate to={redirectPath} replace />;
    }



    return children || <Outlet />;
};

export default ProtectedRoute;