import {FC, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import "../Form/input-file.scss";
import formFields from "./formFields";
import Form from "../Form/Form";
import {UserFormProps} from "./type";
import {UserType} from "../../../pages/buyer/Users/type";
import {useGetUserChannelsListQuery, useGetUserStylesListQuery} from "../../../api/usersApi";

const UserForm: FC<UserFormProps> = ({mode="create", formData={}, onSubmit=() => {}}) => {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<UserType>();

    const {data: channelsList = []} = useGetUserChannelsListQuery("")
    const {data: stylesList = []} = useGetUserStylesListQuery("")

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const isNotView = mode !== "view";
    const readOnly = !isNotView || loading;
    const formValues = getValues();


    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            reset({
                ...formData,
                channels: (formData.channels as Array<string>).join(","),
                ad_styles: (formData.ad_styles as Array<string>).join(","),
            });
        }
    }, [formData])

    const submitForm = async (data: any) => {
        if (isNotView) {
            const backendData = {
                ...data,
                channels: data.channels.split(",")[0] === "" ? data.channels.split(",").slice(1) : data.channels.split(","),
                ad_styles: data.ad_styles.split(",")[0] === "" ? data.ad_styles.split(",").slice(1) : data.ad_styles.split(","),
            }
            try {
                setLoading(true);
                await onSubmit(backendData);
            } catch (error) {
                console.error("Error while submitting form!");
            } finally {
                setLoading(false);
            }
        }
    }

    const fields = useMemo(() => ({
        ...formFields,
        channels: {
            ...formFields.channels,
            options: channelsList
        },
        ad_styles: {
            ...formFields.ad_styles,
            options: stylesList
        }
    }), [channelsList, stylesList])
    const goBackHandler = () => navigate(-1);
    const formTitle = mode === "create"
        ? "Create new User"
        : mode === "edit"
            ? "Edit User"
            : "View User"

    return (
        <Form
            formValues={formValues}
            title={formTitle}
            control={control}
            errors={errors}
            fields={fields}
            goBackHandler={goBackHandler}
            isNotView={isNotView}
            loading={loading}
            mode={mode}
            readOnly={readOnly}
            submit={handleSubmit(submitForm)}
        />
    )
}

export default UserForm;
