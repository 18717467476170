import React, {useEffect, useState} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TitleImg from "../../assets/images/title.png";
import ReadMoreImg from "../../assets/images/read_more.png";
import DescriptionImg from "../../assets/images/description.png";
import RSImg from "../../assets/images/rs.png";
import ImageElImg from "../../assets/images/image_el.png";
import DraggableItem from "./DraggableItem";

export const ElementsImages = {
    "title": {
        img: TitleImg,
        height: "70"
    },
    "description": {
        img: DescriptionImg,
        height: "150"
    },
    "rs": {
        img: RSImg,
        height: "250"
    },
    "image_el": {
        img: ImageElImg,
        height: "150"
    },
    "read_more": {
        img: ReadMoreImg,
        height: "50"
    },
}

export type ElementsImagesType = "title" | "description" | "rs" | "image_el" | "read_more"

type DragDropElementsType = {
    itemsOrder?: string
    onChange?: (newItems: string) => void
}

const DragDropElements: React.FC<DragDropElementsType> = ({ itemsOrder="title,description,rs,image_el,read_more", onChange=() => {}}) => {
    const [items, setItems] = useState<string[]>(itemsOrder.split(","));
    useEffect(() => {
        setItems(itemsOrder.split(","));
    }, [itemsOrder])

    const onDragEnd = (result: any) => {
        if (!result.destination) return; // Проверяем, что нет назначения или уже идет перетаскивание

        const { source, destination } = result;
        const newItems = Array.from(items); // Создаем копию массива строк
        const [removed] = newItems.splice(source.index, 1); // Удаляем перетаскиваемый элемент
        newItems.splice(destination.index, 0, removed); // Вставляем его в новое место
        setItems(newItems); // Обновляем состояние
        onChange(newItems.join(","));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{minHeight: "600px"}}>
                        {items.map((item, index) => (
                            <DraggableItem key={item} id={item} index={index} height={ElementsImages[item as ElementsImagesType].height}>
                                <img src={ElementsImages[item as ElementsImagesType].img} alt={`item ${index}`}
                                    style={{position: "absolute", top: "0", left: "0", objectFit: "fill", width: "100%", height: "100%"}}
                                />
                            </DraggableItem>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragDropElements;
