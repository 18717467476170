import { Box, Checkbox, FormControlLabel, FormGroup, Menu, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FilterPanelButton from "../FilterPanelButton";
import { ReactComponent as DensityIcon } from "../../../../assets/icons/density.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning.svg";
import Badges from "../TableFilterPanelStatistics/components/Badges";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {StatisticsGroupAndMetricsDataRequestType, useGetStatisticsMetricsQuery} from "../../../../api/statisticsApi";
import Loading from "../../../Loading/Loading";
import {chooseFieldInGroupsOrMetrics} from "../../../../store/reducer/statisticsReducer";

interface IMetrics {
    badges?: boolean;
}
const Metrics = ({badges = false }: IMetrics) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch()
    const request = useAppSelector(state => state.StatisticsReducer)
    const {data: metricList, isLoading} = useGetStatisticsMetricsQuery({metrics: request.metrics, groups: request.groups})

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChooseParam = async (newField: Record<string, any>) => {
        const data = {fieldName: "metrics", newField} as {fieldName: "metrics", newField: StatisticsGroupAndMetricsDataRequestType}
        dispatch(chooseFieldInGroupsOrMetrics(data))
    };

    if(isLoading) <Loading/>

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<DensityIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="Metrics" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: ".75rem",
                        width: "min-content",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    ".MuiMenu-list": {
                        p: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    },
                }}
            >
                <FormGroup
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, auto)",
                        gap: "0.75rem",
                        boxSizing: "border-box",
                    }}
                >
                    {metricList && metricList.length > 0 &&
                        metricList.map((metric, metricIndex) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                >
                                    {metric.label}
                                    {metric.keys.length > 0 &&
                                        metric.keys.map((item, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={request.metrics.findIndex((activeMetric: any) => activeMetric.key === item.key) !== -1}
                                                            disabled={!item.is_enabled}
                                                            onChange={() => handleChooseParam(item)}
                                                        />
                                                    }
                                                    label={`${item.name}`}
                                                />
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {!item.is_enabled && (
                                                        <Tooltip title="cant_select_together_warning">
                                                            <WarningIcon />
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip title={item.hint || ""}>
                                                        <HelpOutlineIcon />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        ))}
                                </Typography>
                            </Box>
                        ))}
                </FormGroup>
            </Menu>
            {badges && <Badges filterGroup={request.metrics} removeBadge={handleChooseParam} keyField="key" request={request} field="metrics" />}
        </Box>
    );
};

export default Metrics;
