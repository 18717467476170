import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {generateId} from "../../services/helpers";
import {IMetric} from "../../pages/buyer/Statistics";
import {StatisticsGroupAndMetricsDataRequestType} from "../../api/statisticsApi";

export type DayJSType = string | number | dayjs.Dayjs | Date | null | undefined
export type FilterKey = "channel__name_custom" | "channel__name_custom__icontains" | "country__name" | "country__name__icontains" | ""


type initialStateType = {
    periods: {
        date_from: DayJSType
        date_to:  DayJSType
        currency: string
        periodHotKey: any
    },
    filters: FiltersRequestDataType[]
    metrics:StatisticsGroupAndMetricsDataRequestType[],
    groups: StatisticsGroupAndMetricsDataRequestType[]
}

export type FiltersRequestDataType = {
    fieldId: string,
    key:  FilterKey,
    option: string,
    value:  string,
}

const serialiseInitialStateSearchParams = () => {
    const request = window.location.search
        .split("&")
        .find(el => el.substring(0, 7) === "request")
        ?.split("=")[1]
    return  request && JSON.parse(decodeURIComponent(request));
}

const decodeRequestSearchParams = serialiseInitialStateSearchParams()

const initialRequest: initialStateType = {
    periods: {
        date_from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        date_to: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        currency: "",
        periodHotKey: null
    },
    filters: [
        {
            fieldId: generateId(),
            key: "",
            option: "",
            value: "",
        },
    ],
    metrics: [],
    groups: [
        {
            key: "date__month",
            name: "month",
            is_enabled: true,
        }
    ],
};

const statisticsSlice = createSlice({
    name: "statisticsSlice",
    initialState: decodeRequestSearchParams || initialRequest,
    reducers: {
        setRequest: (state, action:PayloadAction<initialStateType>) => action.payload,
        setRequestStatistics: (state, action: PayloadAction<{field: "filters" | "metrics" | "groups", updateDataRequest: any}>) => {
            const {field, updateDataRequest} = action.payload
            state[field] = updateDataRequest
        },
        setStatisticsPeriods: (state, action: PayloadAction<{date_from?: DayJSType, date_to?: DayJSType, periodType?: string}>) => {
            const { date_from, date_to, periodType} = action.payload
            switch (periodType) {
                case "date_from":
                    state.periods.date_from = dayjs(date_from).format("YYYY-MM-DD")
                    break
                case "date_to":
                    state.periods.date_to = dayjs(date_to).format("YYYY-MM-DD")
                    break
                default: Object.assign(state.periods, { date_from, date_to})
            }
        },
        addNewFilter: (state, action:PayloadAction<{ newFilter: FiltersRequestDataType }>) => {
            state.filters.push(action.payload.newFilter)
        },
        removeFilter: (state, action: PayloadAction<{filter: Record<string, any>}>) => {
            const {filter} = action.payload
            const filterIndex = state.filters.findIndex((el: FiltersRequestDataType)=> el.fieldId === filter.fieldId)
            if(filterIndex !== -1) state.filters.splice(filterIndex, 1)
        },
        changeFiler: (state, action: PayloadAction<{fieldId: string, fieldKey: "fieldId" | "key" | "option" | "value", value: FilterKey | string}>) => {
            const {fieldId, fieldKey, value} = action.payload
            const fieldIndex = state.filters.findIndex((el: FiltersRequestDataType) => el.fieldId === fieldId)
            if(fieldIndex !== -1) (state.filters[fieldIndex] as any)[fieldKey] = value
        },
        chooseFieldInGroupsOrMetrics: (state, action: PayloadAction<{fieldName: "metrics" | "groups", newField: StatisticsGroupAndMetricsDataRequestType }>) => {
            const {fieldName, newField } = action.payload
            const stateGroups = state[fieldName]
            const fieldIndex =  stateGroups.findIndex((item: IMetric) => item.key === newField.key)
            if(fieldIndex !== -1){
                stateGroups.splice(fieldIndex, 1)
            }else{
                stateGroups.push(newField)
            }
        }
    }
})

export const {
    setRequest,
    setRequestStatistics,
    setStatisticsPeriods,
    addNewFilter,
    removeFilter,
    changeFiler,
    chooseFieldInGroupsOrMetrics
} = statisticsSlice.actions

export default statisticsSlice.reducer