import {useNavigate, useParams} from "react-router-dom";
import {FC} from "react";
import AdStyleForm from "../../../components/Forms/AdStyleForm/AdStyleForm";
import {AdStyleType, UserProps} from "./type";
import {useAppDispatch} from "../../../hooks/redux";
import {setAlertData} from "../../../store/reducer/alertReducer";
import {useCreatStyleMutation, useEditStyleMutation, useGetStyleQuery} from "../../../api/adStylesApi";

const initialStyle = {available_for: [], style_id: "", name: "", site: ""}
const AdStyle: FC<UserProps> = ({ mode="create" }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const {data: style = initialStyle} = useGetStyleQuery(id, {skip: !id})
    const  [editStyle,{isError}] = useEditStyleMutation()
    const  [createStyle] = useCreatStyleMutation()

    const onSubmit = async (data: AdStyleType) => {
        try {
            if (mode === "create") {
                await createStyle(data).unwrap()
            }else{
                await editStyle({data, idStyle: id !}).unwrap()
            }
            dispatch(setAlertData({openAlert: true, typeAlert:"success", messageAlert:`Ad style was successfully ${mode === "create" ? "created" : "edited"}`}))
            navigate("/ad_styles");
        } catch (err) {
            console.log("error")
            dispatch(setAlertData({openAlert: true, typeAlert:"error", messageAlert:`Error occurred while ${mode === "create" ? "creating" : "editing"} ad style`}))
            console.log("Error creating ad style");
        }
    }

    const formData = mode !== "create" && Object.keys(style).length > 0
        ? style as AdStyleType
        : undefined

    return (
        <AdStyleForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default AdStyle;