import { isRejectedWithValue } from "@reduxjs/toolkit";
import {setAlertData} from "../store/reducer/alertReducer";

const errorMiddleware = (store: any) => (next: any) => (action: any) => {
    if (isRejectedWithValue(action)) {
        const { status, data } = action.payload || {};
        const message = data?.detail ?? "Произошла ошибка";
        switch (status) {
            case 401:
                store.dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert:"Вы не авторизованы. Пожалуйста, войдите заново."}))
                break;
            case 403:
                store.dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert:"У вас нет доступа к этому ресурсу."}))
                break;
            case 500:
                store.dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert:"Ошибка сервера. Повторите попытку позже."}))
                break;
            default:
                store.dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert:message}))
                break;
        }
    }

    return next(action);
};

export default errorMiddleware;
