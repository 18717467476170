import React from "react";
import CustomMenuCell from "../components/CustomMenuCell/CustomMenuCell";
import {FetchResponseTableUsersListType} from "./usersApi";

export type ResponseDataTableType = {
    rows: RowsType[]
    columns: {
        field: any
        headerName: string
        flex?: number
        width: number
        sortable?: boolean,
        filterable?: boolean,
        disableColumnMenu?: boolean,
        renderCell?: any
    }[]
}

export type RowsType = {
    first_name: string
    id: number
    last_name: string
    username: string
}

type UpdateTableParamsType = {
    responseTableData: FetchResponseTableUsersListType
    namePage?: string
}

const updateTable = ({responseTableData, namePage}: UpdateTableParamsType) => {

    const tempdata: ResponseDataTableType = {
        rows: responseTableData.rows,
        columns: responseTableData.headers.map((col: any) => {
            if(namePage === "rsTable"){
                return {
                    field: col.key,
                    headerName: col.label,
                    width: col.width
                        ? col.width
                        : col.key === "url"
                            ? 300
                            : col.key === "id"
                                ? 50
                                : 125
                }
            }
            return {
                field: col.key,
                headerName: col.label,
                flex: col.key === "id" ? 0 : 1,
                width: col.key === "id" ? 50 : 125
            }

        })
    }

    const getConfigMenuCell = () => {
        switch (namePage) {
            case "rsTable": return {view: true, delete: true, copy: true, edit: true}
            case "userTable": return {view: true, delete: false, copy: false, edit: true}
            default: return  {view: true, delete: true, copy: false, edit: true}
        }
    }

    tempdata.columns.unshift({
        field: "actions",
        headerName: "Actions",
        width: namePage === "rsTable" ? 75 : 100,
        sortable: false, // Отключение сортировки для этой колонки
        filterable: false, // Отключение фильтрации для этой колонки
        disableColumnMenu: true, // Отключение меню колонки
        renderCell:(cellParams: any) => <CustomMenuCell actions={getConfigMenuCell()} cellParams={cellParams} />,
    })

    return tempdata
}

export default updateTable