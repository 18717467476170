import React from "react";
import {Box, Typography} from "@mui/material";
import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import NothingFound from "../../../components/NothingFound";
import Loading from "../../../components/Loading/Loading";
import Period from "../../../components/TableConstructor/components/Period";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {useGetDashboardTableQuery} from "../../../api/dashboardApi";
import {DayJSType, setPeriods} from "../../../store/reducer/periodTableReducer";
import LineGraphDashboard from "./LineGraphDashboard";
import {ResponseDashboardOrStatisticsDataTableType} from "../../../api/updateDashboardOrStatisticsTable";


const initialTableData: ResponseDashboardOrStatisticsDataTableType<"withGroup"> = {
    headers: [],
    rows: [],
    group: {
        key: "",
        name: "",
        is_enabled: false,
        hint: "",
    }
}

const DashboardPage = () => {
    const apiRef = useGridApiRef();
    const periods = useAppSelector(state => state.PeriodTableReducer)
    const dispatch = useAppDispatch()

    const {data: responseTableData = initialTableData, isLoading} = useGetDashboardTableQuery(periods)
    const changePeriodHandler = (periodsArg: {date_from?:DayJSType, date_to?: DayJSType, periodType?: string}) => {
        dispatch(setPeriods({...periodsArg}))
    }

    if(isLoading) return <Loading/>

    return (
        <>
            <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", mb: "1.5rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "1.5rem"}}} component="h1">
                    Dashboard
                </Typography>
            </Box>
            <Box sx={{p: ".5rem", mb: "1rem", borderRadius: ".25rem", border: "1px solid rgba(0, 0, 0, 0.12)"}}>
                <Period changePeriod={changePeriodHandler} request={periods} isStatistics={false} />
            </Box>
            {responseTableData?.rows &&
                (responseTableData.headers.length > 0 && responseTableData.rows.length > 0 ? (
                    <>
                        <LineGraphDashboard
                            loading={isLoading}
                            requests={responseTableData}
                            rows={responseTableData.rows.slice(1)}
                            headers={responseTableData.headers}
                            groupingLabel={responseTableData.group}
                        />

                        <DataGrid
                            apiRef={apiRef}
                            rows={responseTableData?.rows ?? []}
                            columns={responseTableData?.headers ?? []}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            autoHeight
                            loading={isLoading}
                            hideFooterSelectedRowCount
                            getRowClassName={params => params.id === "-" ? "bold-row" : ""}
                        />

                    </>
                ) : (
                    <NothingFound />
                ))}
        </>
    );
};

export default DashboardPage;
