import React from "react";
import {Link} from "react-router-dom";
import {FetchResponseDashboardOrStatisticsTableType} from "./dashboardApi";

export type ResponseDashboardOrStatisticsDataTableType <HasGroup extends "withGroup" | "withoutGroup"> = {
    headers: {
        field: any,
        headerName: any
        type: any
        width: any
        sortComparator: any
        renderCell: any
    }[]
    rows: Row[]
    group: HasGroup extends "withGroup" ? IMetric : undefined;
    metrics?: Record<string, any>[]
    groups?: Record<string, any>[]
    total?: Record<string, any>
}

export interface Action {
    label: string;
    action: string;
}

export interface Row {
    id?: number;
    [key: string]: any;
    actions?: Action[];
}

export interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}

const updateDashboardOrStatisticsTable = <HasGroup extends "withGroup" | "withoutGroup"> ({responseTableData}: {responseTableData: FetchResponseDashboardOrStatisticsTableType}) => {
    const tempdata: ResponseDashboardOrStatisticsDataTableType<HasGroup> = {
        rows: [
            {
                id: "-", ...responseTableData.total
            },
            ...responseTableData.rows
        ],
        headers:  responseTableData.headers.map((col: any) => ({
            field: col.key,
            headerName: col.label,
            type: col.type || "string",
            width: col.key === "id"
                ? 50
                : col.key === "date"
                    ? 100
                    : col.key === "channel__name_custom"
                        ? 100
                        : col.key === "cost_fb_sum"
                            ? 85
                            : col.key === "campaign_prefix"
                                ? 120
                                : 110,
            sortComparator: (value1: any, value2: any, params1: any, params2: any) => {
                if (params1.id === "-" || params2.id === "-") {
                    return 0;
                }
                if (col.type === "string") {
                    return value1.localeCompare(value2);
                }
                return value1 - value2;
            },
            renderCell: (params: any) => {
                if (params.colDef.field === "channel__name_custom") {
                    return <Link to={`/rs?channel=${params.formattedValue}`} target="_blank" style={{fontWeight: "600", textDecoration: "underline !important", color: "var(--main-purple-color)"}}>
                        {params.formattedValue}
                    </Link>
                }
                if (params.colDef.type === "number") {
                    return params.formattedValue.toLocaleString("ru-RU", {
                        maximumFractionDigits: 3,
                    });
                }
                return params.formattedValue;
            }
        })),
        group:  responseTableData.group as HasGroup extends "withGroup" ? IMetric : undefined,
        groups: responseTableData.groups,
        metrics: responseTableData.metrics,
    }

    return tempdata
}

export default updateDashboardOrStatisticsTable