import React from "react";
import "./index.css"
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

const Forbidden = () => {
    const navigate = useNavigate()
    return (
        <div className="not_found_block">
            <span className="main_text">403</span>
            <div className="description_cont">
                <span className="description_text_1">Page forbidden</span>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <span className="description_text_2">You don't have access rights to this page.</span>
                <Button variant="contained" onClick={() => navigate(-1)}>Back</Button>
            </div>
        </div>
    )
}


export default Forbidden