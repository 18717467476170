import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FC, useState } from "react";
import { ReactComponent as DraftIcon } from "../../../../../assets/icons/draftIconBlack.svg";
import { ReactComponent as ExportIcon } from "../../../../../assets/icons/export.svg";
import CustomButton from "../../../../../UI/Buttons/CustomButton";
import ValueInputField from "../../../../../UI/Inputs/ValueInputField";
import {setAlertData} from "../../../../../store/reducer/alertReducer";
import {useSaveStatisticsTemplateTableMutation} from "../../../../../api/statisticsApi";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {changeNameStatisticsTemplate} from "../../../../../store/reducer/templateStatisticsReducer";

type DraftReportsProps = {
    exportReportCSV?: () => void;
    loading?: boolean;
};

const DraftReports: FC<DraftReportsProps> = ({ loading = false, exportReportCSV = () => {},}) => {

    const dispatch = useAppDispatch()
    const requests = useAppSelector(state => state.StatisticsReducer)
    const {nameTemplateStatistics} = useAppSelector(state => state.TemplateStatisticsReducer)

    const [saveTemplate] = useSaveStatisticsTemplateTableMutation()
    const saveReportHandler = async () => {
        try {
            const res = await saveTemplate({name: nameTemplateStatistics, template: requests}).unwrap()
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: res.message}))
        } catch (err: any) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: err.response.data.message}))
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: ".75rem",
                mb: ".75rem",
                pointerEvents: loading ? "none" : "auto",
                opacity: loading ? ".5" : "1",
            }}
        >
            <ValueInputField
                style={{
                    height: "42",
                    top: "-5",
                    width: "220px",
                    background: "#fff",
                }}
                Icon={<DraftIcon />}
                label="Report name"
                value={nameTemplateStatistics}
                onChange={e => dispatch(changeNameStatisticsTemplate({nameTemplateStatistics: e.target.value}))}
            />
            <CustomButton Icon={<SaveIcon />} label="Save" onChange={saveReportHandler} />
            <CustomButton mode="dark" Icon={<ExportIcon />} label="Export" onChange={exportReportCSV} />
        </Box>
    );
};

export default DraftReports;
