import React, {ReactElement} from "react";
import { Draggable } from "react-beautiful-dnd";
import {Box} from "@mui/material";

interface DraggableItemProps {
    id: string;
    index: number;
    height?: string,
    children: ReactElement;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, index, height="100", children }) => (
    <Draggable draggableId={id} index={index}>
        {provided => (
            <Box
                sx={{
                    ...provided.draggableProps.style,
                    position: "relative",
                    width: {xs: "100%", md: "60%"},
                    height: `${height}px`,
                    border: "2px solid gray",
                    borderBottom: index === 4 ? "" : "0px solid gray"
                }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {children}
            </Box>
        )}
    </Draggable>
);

export default DraggableItem;
