import {useNavigate} from "react-router-dom";
import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Menu, MenuItem} from "@mui/material";
import {useAppDispatch} from "../../hooks/redux";
import {setAlertData} from "../../store/reducer/alertReducer";
import {useActionTableApiMutation} from "../../api/tableActionApi";

const initialCustomMenuCell = {
    actions: {
        view: true,
        edit: true,
        delete: true,
        copy: false
    },
    cellParams: {},
    setIsLoading: (value: boolean) => {}
}

const CustomMenuCell = ({actions=initialCustomMenuCell.actions, cellParams=initialCustomMenuCell.cellParams, setIsLoading=initialCustomMenuCell.setIsLoading}: any) => {
    const dispatch = useAppDispatch()
    const [useActionTable] = useActionTableApiMutation()
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const postAction = async (key: string, id: string) => {
        const system = window.location.pathname.split("/")[1]
        try {
            setIsLoading(true)
            await useActionTable({system, id, key});
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: `${key} succeed! WILL RELOAD NOW`}))
            setIsLoading(false)
        } catch (error) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: `Error occurred while ${key}, try later!`}))
        }
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        navigate(`view/${cellParams.id}`);
        handleClose();
    };

    const handleEdit = () => {
        navigate(`edit/${cellParams.id}`);
        handleClose();
    };

    const handleDelete = async () => {
        console.log(cellParams)
        handleClose();
        const resConfirm  = window.confirm(`Вы действительно хотите удалить строку с ID ${cellParams.id}? Это действие необратимо. Подтвердите удаление.`)
        if(resConfirm) await postAction("delete", cellParams.id);
    }


    const handleCopy = async () => {
        handleClose();
        await postAction("copy", cellParams.id);
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                { actions.view &&
                    <MenuItem onClick={handleOpen}>View</MenuItem>
                }
                { actions.edit &&
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                }
                { actions.copy &&
                    <MenuItem onClick={handleCopy}>Copy</MenuItem>
                }
                { actions.delete &&
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                }
            </Menu>
        </div>
    );
};

export default CustomMenuCell;
