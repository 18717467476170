import {BaseQueryMeta, BaseQueryResult, createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import updateTable, {ResponseDataTableType, RowsType} from "./updateTable";
import {ChannelResponseType} from "./channelsApi";

export type FetchResponseTableUsersListType ={
    headers: Array<{ key: string, label: string }>
    rows: RowsType[]
}

type UserResponseType = {
    first_name: string,
    last_name: string,
    username: string,
    channels: Array<string> | string,
    ad_styles: Array<string> | string,
    user_view: boolean,
    channel_view: boolean,
    style_view: boolean,
    category_view: boolean,
}

export const usersApi = createApi({
    reducerPath: "usersAPI",
    tagTypes: ["mutationUser"],
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        getUserTable: builder.query<ResponseDataTableType, string>({
            query: arg => ({url: "/user/list/"}),
            transformResponse: (responseTableData: FetchResponseTableUsersListType) => updateTable({responseTableData, namePage: "userTable"}),
            providesTags: ["mutationUser"]
        }),
        getUser: builder.query<UserResponseType, string | undefined>({
            query: id => ({url: `/user/${id}/details/`}),
            providesTags: ["mutationUser"]
        }),
        creatUser: builder.mutation<{message: string}, UserResponseType>({
            query: body => ({url: "/user/create/", method: "POST", body}),
            invalidatesTags: ["mutationUser"]
        }),
        editUser: builder.mutation<{message: string}, {data: UserResponseType, userId: string}>({
            query: ({data, userId}) => ({url: `/user/${userId}/update/`, method: "POST", body: data}),
            invalidatesTags: ["mutationUser"]
        }),
        getUserNameList: builder.query<string[], string>({
            query: arg => ({url: "/user/username/list/"}),
        }),
        getUserChannelsList: builder.query<string[], string>({
            query: arg => ({url: "/search/channels/free/"})
        }),
        getUserStylesList: builder.query<string[], string>({
            query: arg => ({url: "/search/ad_styles/array/"})
        })
    })
})

export const {
    useGetUserTableQuery,
    useEditUserMutation,
    useCreatUserMutation,
    useGetUserQuery,
    useGetUserNameListQuery,
    useGetUserChannelsListQuery,
    useGetUserStylesListQuery
} = usersApi
