import React, {FC, useEffect, useRef} from "react";
import "./styles.scss";
import Editor from "./Editor";

// eslint-disable-next-line react/require-default-props
const QuillTextEditor: FC<{readOnly: boolean, value: string, onChange: (value: string) => void}> = ({readOnly=false, value, onChange}) => {

    const quillRef = useRef<any>();

    useEffect(() => {
        if (value !== quillRef.current.getSemanticHTML()) {
            const delta = quillRef.current.clipboard.convert({html: value});
            quillRef.current.setContents(delta);
        }
    }, [value])

    return (
        <>
            <script src="/node_modules/@mgreminger/quill-image-resize-module/dist/index.es.js"></script>
            <Editor
                ref={quillRef}
                readOnly={readOnly}
                defaultValue={value}
                onChange={onChange}
            />
        </>
    )
}

export default QuillTextEditor;