import {useNavigate, useParams} from "react-router-dom";
import {FC} from "react";
import {UserProps, ChannelType} from "./type";
import ChannelForm from "../../../components/Forms/ChannelForm/ChannelForm";
import {useAppDispatch} from "../../../hooks/redux";
import {setAlertData} from "../../../store/reducer/alertReducer";
import {useCreatChanelMutation, useEditChanelMutation, useGetChanelQuery} from "../../../api/channelsApi";

const Channel: FC<UserProps> = ({ mode="create" }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const {data: channel = {channel_name: "", channel: "", username: []}} = useGetChanelQuery(id, {skip: !id});
    const [createChanel] = useCreatChanelMutation()
    const [editChanel] = useEditChanelMutation()

    const onSubmit = async (data: ChannelType) => {
        try {
            if (mode === "create") {
                await createChanel(data).unwrap()
            }else {
                await editChanel({data, idChanel: id !}).unwrap()
            }
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: `Channel was successfully ${mode === "create" ? "created" : "edited"}`}))
            navigate("/channels");
        } catch (err) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: `Error occurred while ${mode === "create" ? "creating" : "editing"} channel`}))
            console.log("Error creating related search");
        }
    }

    const formData = mode !== "create" && Object.keys(channel).length > 0
        ? channel as ChannelType
        : undefined

    return (
        <ChannelForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default Channel;