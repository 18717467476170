import {FC, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import "../Form/input-file.scss";
import formFields from "./formFields";
import Form from "../Form/Form";
import {AdStyleFormProps} from "./type";
import {AdStyleType} from "../../../pages/buyer/AdStyles/type";
import {useGetAfsSiteListQuery} from "../../../api/afsSiteListApi";
import {useGetUserNameListQuery} from "../../../api/usersApi";

const AdStyleForm: FC<AdStyleFormProps> = ({mode="create", formData={}, onSubmit=() => {}}) => {
    const {
        control, handleSubmit, reset,
        getValues, formState: { errors },
    } = useForm<AdStyleType>();

    const {data: sitesList = []} = useGetAfsSiteListQuery("")
    const {data: usersList = []} = useGetUserNameListQuery("")

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const isNotView = mode !== "view";
    const readOnly = !isNotView || loading;
    const formValues = getValues();


    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            reset({
                ...formData,
                available_for: (formData.available_for as Array<string>).join(","),
            });
        }
    }, [formData])

    const submitForm = async (data: any) => {
        if (isNotView) {
            const backendData = {
                ...data,
                available_for: data.available_for.split(",")[0] === "" ? data.available_for.split(",").slice(1) : data.available_for.split(","),
            }
            try {
                setLoading(true);
                await onSubmit(backendData);
            } catch (error) {
                console.error("Error while submitting form!");
            } finally {
                setLoading(false);
            }
        }
    }

    const fields = useMemo(() => ({
        ...formFields,
        available_for: {
            ...formFields.available_for,
            options: usersList
        },
        site: {
            ...formFields.site,
            options: sitesList
        }
    }), [usersList, sitesList])
    const goBackHandler = () => navigate(-1);
    const formTitle = mode === "create"
        ? "Create new Ad style"
        : mode === "edit"
            ? "Edit Ad style"
            : "View Ad style"

    return (
        <Form
            formValues={formValues}
            title={formTitle}
            control={control}
            errors={errors}
            fields={fields}
            goBackHandler={goBackHandler}
            isNotView={isNotView}
            loading={loading}
            mode={mode}
            readOnly={readOnly}
            submit={handleSubmit(submitForm)}
        />
    )
}

export default AdStyleForm;
