import React, {useState} from "react";
import {Dayjs} from "dayjs";
import {useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import LineGraphRecharts from "./components/LineGraphRecharts";
import DraftReports from "./components/DraftReports";
import DraftReportsModal from "./components/DraftReportsModal";
import {ReactComponent as SaveIconWhite} from "../../../assets/icons/saveIconWhite.svg";
import {ReactComponent as UpdateIconWhite} from "../../../assets/icons/updateIcon.svg";
import {thickRow} from "../../../components/TableConstructor/constants";
import DraftButton from "../../../UI/Buttons/Actions/DraftButton";
import TableFilterPanelStatistics from "../../../components/TableConstructor/components/TableFilterPanelStatistics";
import CustomButton from "../../../UI/Buttons/CustomButton";
import NothingFound from "../../../components/NothingFound";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setAlertData} from "../../../store/reducer/alertReducer";
import {
    useCreateStatisticsTableMutation, useExportTableCSVMutation,
    useSaveStatisticsTemplateTableMutation
} from "../../../api/statisticsApi";
import updateDashboardOrStatisticsTable, {
    ResponseDashboardOrStatisticsDataTableType
} from "../../../api/updateDashboardOrStatisticsTable";

export interface IPeriod {
    date_from: Dayjs | null;
    date_to: Dayjs | null;
    currency: string;
}

export interface IFilter {
    fieldId: string;
    key: string;
    option: string;
    value: string;
}
export interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}
export type IRequests = Record<string, any>;
export interface IAggregation {
    label: string;
    keys: IMetric[];
}

const CreateIconTsx = () => (
    <SaveIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);
const UpdateIconTsx = () => (
    <UpdateIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);

const initialTableData: ResponseDashboardOrStatisticsDataTableType<"withoutGroup"> = {
    headers: [],
    rows: [],
    group: undefined
}

const ReportsPage = () => {
    const apiRef = useGridApiRef();
    const dispatch = useAppDispatch()
    const requests = useAppSelector(state => state.StatisticsReducer)
    const [isOpenDraftModal, setIsOpenDraftModal] = useState<boolean>(false);
    const [tableActions, setTableActions] = useState<any>({
        period: true,
        is_report_filter: true,
        grouping: true,
        metrics: true,
        row_dense: false
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [rowDense, setRowDense] = useState(thickRow);
    const [createTable,
        {
            isLoading: isLoadingDataTable,
            isSuccess: isSuccessDataTable,
            data: responseTableData = initialTableData
        }
    ] = useCreateStatisticsTableMutation()
    const [exportCSVTable] = useExportTableCSVMutation()
    const [buttonProps, setButtonProps] = useState<any>({
        props: [
            {
                svg: <CreateIconTsx />,
                label: "Create",
            },
            {
                svg: <UpdateIconTsx />,
                label: "Update",
            },
        ],
        state: 0,
    });
    const openOpenDraftModal = () => {
        setIsOpenDraftModal(true);
    };
    const handleCloseDraftModal = () => {
        setIsOpenDraftModal(false);
    };

    const mandatoryOptions = ["date__month", "date", "date__year", "week", "period"];
    const handleUpdate = async (signal: any = null) => {
        try {
            await createTable(requests);
            const size =  50;
            const page = "1";
            setSearchParams({
                ...searchParams,
                page,
                size,
                requests: JSON.stringify(requests),
            });

        } catch (err: any) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: err.response.data.message}))
        } finally {
            setButtonProps((prev: any) => ({ ...prev, state: 1 }));
        }
    };



    const exportReportCSV = async () => {
        try {
            const response = await exportCSVTable(requests).unwrap()
            const {blobUrl, filename} = response
            if (blobUrl && filename) {
                // Преобразуем blobUrl обратно в Blob для чтения данных
                const responseBlob = await fetch(blobUrl).then(res => res.blob());

                // Читаем содержимое Blob как текст
                const text = await responseBlob.text();
                const jsonData = JSON.parse(text);

                // Создаём новый Blob и URL для загрузки
                const fileBlob = new Blob([jsonData], {
                    type: "application/json",
                });
                const fileUrl = URL.createObjectURL(fileBlob);

                // Создаём ссылку и скачиваем файл
                const a = document.createElement("a");
                a.href = fileUrl;
                a.download = filename;
                a.click();
                URL.revokeObjectURL(fileUrl); // Очищаем URL
            }
        } catch (e: any) {
            console.error(e.response.data.message);
        }
    };



    return (
        <>
            <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", mb: "1.5rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "1.5rem"}}} component="h1">
                    Statistics
                </Typography>
                <Box sx={{display: "flex", width: {xs: "100%", md: "auto"}, gap: ".25rem"}}>
                    <DraftButton action={openOpenDraftModal} />
                    <CustomButton
                        style={{width: {xs: "100%", md: "auto"}}}
                        mode={requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key)) ? "warning" : "dark"}
                        label={buttonProps.props[buttonProps.state].label}
                        onChange={handleUpdate}
                        disabled={!requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key))}
                    />
                </Box>
            </Box>
            <TableFilterPanelStatistics
                rowDense={rowDense}
                setRowDense={setRowDense}
                loading={isLoadingDataTable}
                rows={responseTableData.rows || []}
                tableActions={tableActions}
                isStatistics
            />
            {
                isSuccessDataTable
                    ?   responseTableData.headers.length > 0 && responseTableData.rows.length > 0
                        ? <>

                            <DraftReports
                                loading={isLoadingDataTable}
                                exportReportCSV={exportReportCSV}
                            />

                            <LineGraphRecharts
                                loading={isLoadingDataTable}
                                requests={requests}
                                rows={responseTableData.rows.slice(1)}
                                headers={responseTableData.headers}
                                groupingLabel={responseTableData.group}
                            />

                            <DataGrid
                                sx={{
                                    "@media (hover: none)": {
                                        "&& .MuiDataGrid-menuIcon": {
                                            width: 0,
                                            visibility: "hidden",
                                        }
                                    },
                                    "&& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon": {
                                        width: "auto",
                                        visibility: "visible",
                                    },
                                    "&& .MuiDataGrid-columnHeader": {
                                        p: {xs: "0 2px", md: "0 10px"}
                                    }

                                }}
                                apiRef={apiRef}
                                rows={responseTableData.rows}
                                columns={responseTableData.headers}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 15 },
                                    },
                                }}
                                pageSizeOptions={[15, 25, 50, 100]}
                                autoHeight
                                loading={isLoadingDataTable}
                                hideFooterSelectedRowCount
                                getRowClassName={params => params.id === "-" ? "bold-row" : ""}
                            />

                        </>
                        : <NothingFound />
                    : null
            }
            <DraftReportsModal open={isOpenDraftModal} handleClose={handleCloseDraftModal} />
        </>
    );
};

export default ReportsPage;
