import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import {DayJSType} from "../store/reducer/periodTableReducer";
import {FilterKey} from "../store/reducer/statisticsReducer";
import updateTable from "./updateTable";
import {FetchResponseTableUsersListType} from "./categoriesApi";
import updateDashboardOrStatisticsTable, {
    ResponseDashboardOrStatisticsDataTableType
} from "./updateDashboardOrStatisticsTable";

type RequestDataType = {
    periods: {
        date_from: DayJSType
        date_to:  DayJSType
        currency: string
        periodHotKey: any
    },
    filters: FiltersRequestDataType[]
    metrics:StatisticsGroupAndMetricsDataRequestType[],
    groups: StatisticsGroupAndMetricsDataRequestType[]
}

export type FiltersRequestDataType = {
    fieldId: string,
    key:  FilterKey,
    option: string,
    value:  string,
}

// Filters Response Data

export type StatisticsFiltersResponseType = {
    channel__name_custom: IFilterItems
    channel__name_custom__icontains: IFilterItems
    country__name: IFilterItems
    country__name__icontains: IFilterItems
}

type IFilterItems = {
    column: string;
    options: string[];
    filters?: string[];
}

//  Groups Response Data

export type StatisticsGroupAndMetricsResponseType = StatisticsGroupAndMetricsType[]
type StatisticsGroupAndMetricsType = {label: string, keys: StatisticsGroupAndMetricsDataRequestType[]}
export type StatisticsGroupAndMetricsDataRequestType = {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}

// Create Statistics Table

type CreateTableDataRequestType = RequestDataType

type CreateTableDataResponseType = {
    headers: Record<string, any>[]
    rows:Row[],
    total: Record<string, any>
}

export interface Action {
    label: string;
    action: string;
}

export interface Row {
    id: number;
    [key: string]: any;
    actions?: Action[];
}

// Draft template

type StatisticsAllDraftTemplatesResponse  = Array<{ id: number, name: string }>

type StatisticsDraftTemplateResponse  = {
    editable: boolean
    template: RequestDataType
}


export const statisticsApi = createApi({
    reducerPath: "statisticsApi",
    tagTypes: ["templateList"],
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        createStatisticsTable: builder.mutation<ResponseDashboardOrStatisticsDataTableType<"withoutGroup">, CreateTableDataRequestType>({
            query: body => ({url: "/report/create/", method: "POST", body}),
            transformResponse: (responseTableData: CreateTableDataResponseType) => updateDashboardOrStatisticsTable<"withoutGroup">({responseTableData}),
        }),
        getStatisticsFilters: builder.query<StatisticsFiltersResponseType, any>({
            query: arg => ({url: "/report/filters/", method: "POST"}),
        }),
        getStatisticsGroups: builder.query<StatisticsGroupAndMetricsResponseType, {groups: Record<string, any>[], metrics: Record<string, any>[]}>({
            query: body => ({url: "/report/groups/", method: "POST", body}),
        }),
        getStatisticsMetrics: builder.query<StatisticsGroupAndMetricsResponseType, {groups: Record<string, any>[], metrics: Record<string, any>[]}>({
            query: body => ({url: "/report/metrics/", method: "POST", body}),
        }),
        getStatisticsAllTemplatesTable: builder.query<StatisticsAllDraftTemplatesResponse, any>({
            query: arg => ({url: "report/templates/"}),
            providesTags: result => ["templateList"],
        }),
        getStatisticsTemplateTable: builder.mutation<StatisticsDraftTemplateResponse, number>({
            query: id => ({url: `report/templates/${id}/`, method: "GET"}),
        }),
        saveStatisticsTemplateTable: builder.mutation<{message: string}, {name: string, template: StatisticsDraftTemplateResponse}>({
            query: body => ({url: "report/templates/create/", method: "POST", body}),
            invalidatesTags: ["templateList"]
        }),
        deleteStatisticsTemplateTable: builder.mutation<{message: string}, number>({
            query: id => ({url: `report/templates/${id}/delete/`, method: "POST"}),
            invalidatesTags: ["templateList"]
        }),
        exportTableCSV: builder.mutation<{blobUrl: string, filename: string}, StatisticsDraftTemplateResponse>({
            query: body => ({
                url: "report/save/",
                method: "POST",
                body,
                responseHandler: (response: any) => response.blob()
            }),
            transformResponse: async (blob: Blob, meta) => {
                console.log()
                const blobUrl = URL.createObjectURL(blob)
                const contentDisposition = meta?.response?.headers?.get("content-disposition");
                const filenameMatch = contentDisposition?.match(/filename="?(.+)"?/);
                const filename = filenameMatch ? filenameMatch[1] : "exported-file.json";
                return {blobUrl, filename,}
            }
        }),
    })
})

export const {
    useGetStatisticsFiltersQuery,
    useGetStatisticsGroupsQuery,
    useGetStatisticsMetricsQuery,
    useCreateStatisticsTableMutation,
    useGetStatisticsAllTemplatesTableQuery,
    useGetStatisticsTemplateTableMutation,
    useDeleteStatisticsTemplateTableMutation,
    useSaveStatisticsTemplateTableMutation,
    useExportTableCSVMutation
} = statisticsApi
