import React, {forwardRef, useEffect, useLayoutEffect, useRef} from "react";
import Quill from "quill";
import ImageResize from "quill-image-resize";
import "./styles.scss";

const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],        // toggled buttons
    // ["blockquote", "code-block"],
    ["blockquote"],

    [{ "header": 1 }, { "header": 2 }],               // custom button values
    // [{ "list": "ordered"}, { "list": "bullet" }, { "list": "check" }],
    [{ "list": "ordered"}, { "list": "bullet" }],
    [{ "script": "sub"}, { "script": "super" }],      // superscript/subscript
    [{ "indent": "-1"}, { "indent": "+1" }],          // outdent/indent
    [{ "direction": "rtl" }],                         // text direction

    [{ "size": ["small", false, "large", "huge"] }],  // custom dropdown
    // [{ "header": [1, 2, 3, 4, 5, 6, false] }],

    [{ "color": [] }, { "background": [] }],          // dropdown with defaults from theme
    [{ "font": [] }],
    [{ "align": [] }],
    // ["link", "image", "video", "formula"],
    // ["link", "image", "video",],
    ["link",],
    ["clean"]                                         // remove formatting button
];


const Editor = forwardRef<any, any>(
    ({ readOnly, defaultValue, onTextChange, onSelectionChange, onChange }, ref) => {
        const containerRef = useRef<any>(null);
        const onTextChangeRef = useRef(onTextChange);
        const onSelectionChangeRef = useRef(onSelectionChange);

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
            onSelectionChangeRef.current = onSelectionChange;
        });

        useEffect(() => {
            const container = containerRef.current;
            const editorContainer = container?.appendChild(
                container.ownerDocument.createElement("div"),
            );
            Quill.register("modules/imageResize", ImageResize)

            const quill = new Quill(editorContainer, {
                modules: {
                    imageResize: {
                        modules: [ "Resize", "DisplaySize" ]
                        // modules: {}
                    },
                    toolbar: toolbarOptions
                },
                readOnly,
                theme: "snow",
            });

            (ref as any).current = quill;

            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                onTextChangeRef.current?.(...args);
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                onSelectionChangeRef.current?.(...args);
            });

            quill.on(Quill.events.TEXT_CHANGE, e => {
                onChange(quill.getSemanticHTML());
            });

            return () => {
                (ref as any).current = null;
                container.innerHTML = "";
            };
        }, [ref]);

        return (
            <>
                <link href="https://cdn.jsdelivr.net/npm/quill@2.0.3/dist/quill.snow.css" rel="stylesheet" />
                <div style={{minHeight: "500px", marginBottom: "44px"}} ref={containerRef}></div>
            </>
        );
    }
);

Editor.displayName = "Editor";

export default Editor;