import React from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useGetUserTableQuery} from "../../../api/usersApi";

const Users = () => {
    const navigate= useNavigate();

    const {data: responseTableData, isLoading, isSuccess} = useGetUserTableQuery("")
    const goToCreateRelatedSearch = () => navigate("create");


    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "0", md: "1.5rem"}, py: "1rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "2.125rem"}}} component="h1">
                    User management
                </Typography>
                <Button sx={{minWidth: "48px", fontSize: {xs: ".75rem", md: ".875rem"}}} variant="contained" color="info" onClick={goToCreateRelatedSearch}>
                    + Create
                </Button>
            </Box>
            <DataGrid
                rows={responseTableData?.rows ?? [] }
                columns={responseTableData?.columns ?? []}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                autoHeight
                loading={isLoading}
                hideFooterSelectedRowCount
            />
        </>
    )
}

export default Users;