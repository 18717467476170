import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import updateTable, {ResponseDataTableType, RowsType} from "./updateTable"
import {RelatedSearchType} from "../pages/buyer/RelatedSearchs/type";

export type FetchResponseTableUsersListType ={
    headers: Array<{ key: string, label: string }>
    rows: RowsType[]
}

type TermsStatisticsResponseDataType = {
    terms: {
        headers: {key: string, label: string}[]
        rows: Record<string, any>[]
    }
}

type ReturnDataStatisticsTableType = {
    cols: {
        field: string,
        headerName: string,
        flex: number,
        width: number
        type?: string,
        sortable?: boolean
    }[]
    rows: Record<string, any>[]
}

export const relatedSearchesApi = createApi({
    reducerPath: "relatedSearchesApi",
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    tagTypes: ["mutationRS"],
    endpoints: builder => ({
        getRelatedSearchesTable: builder.query<ResponseDataTableType, string>({
            query: query => ({url: `/search/related_searchs/?${query}`}),
            providesTags:  ["mutationRS"],
            transformResponse: (responseTableData: FetchResponseTableUsersListType) => updateTable({responseTableData, namePage:"rsTable"}),
        }),
        getRS: builder.query<any, string | undefined>({
            query: id => ({url: `/search/related_searchs/${id}/`}),
            providesTags:  ["mutationRS"],
            transformResponse: (response: RelatedSearchType) => ({
                ...response,
                terms: response.terms.map((term: string) => ({name: term})),
                terms_2: response.terms_2 ? response.terms_2.map((term: string) => ({name: term})) : [{name: ""}],
            })
        }),
        creatRS: builder.mutation<{message: string}, any>({
            query: body => ({url: "/search/related_searchs/create/", method: "POST", body}),
            invalidatesTags: ["mutationRS"]
        }),
        editRS: builder.mutation<{message: string}, {data: any, idRs: string}>({
            query: ({data, idRs}) => ({
                url: `/search/related_searchs/${idRs}/edit/`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["mutationRS"]
        }),
        getTermsStatistics: builder.query<ReturnDataStatisticsTableType, {id: string, period: any}>({
            query: ({id , period}) => ({url: `/search/related_searchs/${id}/statistics/`, method: "POST", body:period}),
            transformResponse: (response: TermsStatisticsResponseDataType) => ({
                cols: response.terms.headers.map((col: any) => ({
                    field: col.key,
                    headerName: col.label,
                    flex: col.key === "id" || col.key === "url" ? 0 : 1,
                    width: col.key === "id"
                        ? 50
                        : 125
                })),
                rows: response.terms.rows
            })
        }),
        getStyleCurrentSite: builder.query<string[], string>({
            query: currentSite => ({url: `/search/ad_styles/?site=${currentSite}`}),
        }),
        getCategoryCurrentSite: builder.query<string[], string>({
            query: currentSite => ({url: `/search/ad_categories/?site=${currentSite}`}),
        }),
        getTemplates: builder.query<string[], string>({
            query: currentSite => ({url: "/search/ad_templates/"}),
        }),
        getChannels: builder.query<string[], {mode: string, id: string}>({
            query: ({mode, id}) => ({url: `/search/channels/user/${mode !== "create" ? `?rs_id=${id}` : ""}`}),
        }),

    })
})

export const {
    useGetRelatedSearchesTableQuery,
    useGetRSQuery,
    useGetTermsStatisticsQuery,
    useCreatRSMutation,
    useEditRSMutation,
    useGetCategoryCurrentSiteQuery,
    useGetStyleCurrentSiteQuery,
    useGetChannelsQuery,
    useGetTemplatesQuery,
} = relatedSearchesApi
