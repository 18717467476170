import {Alert, Snackbar} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setAlertData, StateAlertType} from "../../store/reducer/alertReducer";

const AlertNotification = () => {

    const {
        openAlert, typeAlert, messageAlert
    } = useAppSelector(state => state.AlertReducer)
    const dispatch = useAppDispatch()
    const handleCloseAlert = () => {
        const alertData: StateAlertType =  {openAlert: false, messageAlert, typeAlert}
        dispatch(setAlertData(alertData))
    }

    return(
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openAlert}
            onClose={handleCloseAlert}
            key="top center"
            autoHideDuration={3000}
        >
            <Alert
                onClose={handleCloseAlert}
                severity={typeAlert}
                sx={{ width: "100%" }}>
                { messageAlert }
            </Alert>
        </Snackbar>
    )
}

export  default  AlertNotification