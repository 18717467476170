import {FC, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import "../Form/input-file.scss";
import formFields from "./formFields";
import Form from "../Form/Form";
import {ChannelFormProps} from "./type";
import {ChannelType} from "../../../pages/buyer/Channels/type";
import {useGetUserNameListQuery} from "../../../api/usersApi";

const ChannelForm: FC<ChannelFormProps> = ({mode="create", formData={}, onSubmit=() => {}}) => {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<ChannelType>();
    const [loading, setLoading] = useState<boolean>(false);
    const {data: usersList = []} = useGetUserNameListQuery("")
    const navigate = useNavigate();

    const isNotView = mode !== "view";
    const readOnly = !isNotView || loading;
    const formValues = getValues();


    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            reset({
                ...formData,
                username: (formData.username as Array<string>).join(","),
            });
        }
    }, [formData])

    const submitForm = async (data: any) => {
        if (isNotView) {
            const backendData = {
                ...data,
                username: data.username.split(",")[0] === "" ? data.username.split(",").slice(1) : data.username.split(",")
            }
            try {
                setLoading(true);
                await onSubmit(backendData);
            } catch (error) {
                console.error("Error while submitting form!");
            } finally {
                setLoading(false);
            }
        }
    }

    const fields = useMemo(() => ({
        ...formFields,
        username: {
            ...formFields.username,
            options: usersList
        },
    }), [usersList])
    const goBackHandler = () => navigate(-1);
    const formTitle = mode === "create"
        ? "Create new Channel"
        : mode === "edit"
            ? "Edit Channel"
            : "View Channel"

    return (
        <Form
            formValues={formValues}
            title={formTitle}
            control={control}
            errors={errors}
            fields={fields}
            goBackHandler={goBackHandler}
            isNotView={isNotView}
            loading={loading}
            mode={mode}
            readOnly={readOnly}
            submit={handleSubmit(submitForm)}
        />
    )
}

export default ChannelForm;
