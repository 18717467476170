import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import updateTable, {ResponseDataTableType, RowsType} from "./updateTable"
import {CategoryType} from "./categoriesApi";

export type FetchResponseTableUsersListType ={
    headers: Array<{ key: string, label: string }>
    rows: RowsType[]
}

export type StyleResponseType = {
    available_for: string[]
    name: string
    site: string
    style_id: string
}

type StyleRequestDataType = {
    name: string,
    style_id: string,
    available_for: Array<string> | string,
}

export const adStylesApi = createApi({
    reducerPath: "adStylesApi",
    tagTypes: ["mutationStyle"],
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        getAdStylesTable: builder.query<ResponseDataTableType, string>({
            query: arg => ({url: "/search/ad_styles/list/"}),
            providesTags: ["mutationStyle"],
            transformResponse: (responseTableData: FetchResponseTableUsersListType) => updateTable({responseTableData}),
        }),
        getStyle: builder.query<StyleResponseType, string | undefined>({
            query: id => ({url: `/search/ad_styles/${id}/details/`}),
            providesTags: ["mutationStyle"]
        }),
        creatStyle: builder.mutation<{message: string}, StyleRequestDataType>({
            query: body => ({url: "/search/ad_styles/create/", method: "POST", body}),
            invalidatesTags: ["mutationStyle"]
        }),
        editStyle: builder.mutation<{message: string}, {data: StyleRequestDataType, idStyle: string}>({
            query: ({data, idStyle}) => ({url: `/search/ad_styles/${idStyle}/edit/`, method: "POST", body: data}),
            invalidatesTags: ["mutationStyle"]
        }),
    })
})

export const {
    useGetAdStylesTableQuery,
    useEditStyleMutation,
    useCreatStyleMutation,
    useGetStyleQuery
} = adStylesApi
