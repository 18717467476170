import {combineReducers, configureStore} from "@reduxjs/toolkit";
import AlertReducer from "./reducer/alertReducer"
import StatisticsReducer from "./reducer/statisticsReducer"
import PeriodTableReducer from "./reducer/periodTableReducer"
import TemplateStatisticsReducer from "./reducer/templateStatisticsReducer"
import {authAPI} from "../api/authApi";
import {usersApi} from "../api/usersApi";
import {adStylesApi} from "../api/adStylesApi";
import {channelsApi} from "../api/channelsApi";
import {categoriesApi} from "../api/categoriesApi";
import {relatedSearchesApi} from "../api/relatedSearchecsApi";
import {dashboardApi} from "../api/dashboardApi";
import {statisticsApi} from "../api/statisticsApi";
import {tableActionApi} from "../api/tableActionApi";
import {currenciesApi} from "../api/currenciesApi";
import errorMiddleware from "../api/errorMiddleware";
import {afsSitesListApi} from "../api/afsSiteListApi";


const rootReducer = combineReducers({
    AlertReducer,
    StatisticsReducer,
    PeriodTableReducer,
    TemplateStatisticsReducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [adStylesApi.reducerPath]: adStylesApi.reducer,
    [channelsApi.reducerPath]: channelsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [relatedSearchesApi.reducerPath]: relatedSearchesApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [tableActionApi.reducerPath]: tableActionApi.reducer,
    [currenciesApi.reducerPath]: currenciesApi.reducer,
    [afsSitesListApi.reducerPath]: afsSitesListApi.reducer,
})



export const setupStore = () => (
    configureStore({
        reducer : rootReducer,
        middleware: getDefaultMiddleware => (
            getDefaultMiddleware(
                {
                    serializableCheck: false, // Отключение проверки сериализуемости
                }
            ).concat(
                errorMiddleware,
                authAPI.middleware,
                usersApi.middleware,
                adStylesApi.middleware,
                channelsApi.middleware,
                categoriesApi.middleware,
                relatedSearchesApi.middleware,
                dashboardApi.middleware,
                statisticsApi.middleware,
                tableActionApi.middleware,
                currenciesApi.middleware,
                afsSitesListApi.middleware,
            )
        )
    })
)

export const store = setupStore()

export type RootState = ReturnType<typeof  rootReducer>
export type AppStore = ReturnType<typeof  setupStore>
export type AppDispatch = AppStore["dispatch"]