import { Box, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../../../../components/Modal";
import {useAppDispatch} from "../../../../../hooks/redux";
import {setAlertData} from "../../../../../store/reducer/alertReducer";
import {
    useDeleteStatisticsTemplateTableMutation,
    useGetStatisticsAllTemplatesTableQuery,
    useGetStatisticsTemplateTableMutation
} from "../../../../../api/statisticsApi";
import {setRequest} from "../../../../../store/reducer/statisticsReducer";

type DraftReportsModalProps = {
    open: boolean;
    handleClose: () => void;
};

const DraftReportsModal: FC<DraftReportsModalProps> = ({open, handleClose }) => {
    const dispatch = useAppDispatch()
    const {data: reportsList} = useGetStatisticsAllTemplatesTableQuery("", {skip: !open})
    const [getTemplate] = useGetStatisticsTemplateTableMutation()
    const [deleteTemplate] = useDeleteStatisticsTemplateTableMutation()
    const deleteReport = async (id: number) => {
        try {
            const res = await deleteTemplate(id).unwrap()
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: res.message}))
        } catch (e: any) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: e.response.data.message}))
        }
    };

    const chooseReportHandler = async (id: number) => {
        try {
            const res = await getTemplate(id).unwrap()
            dispatch(setRequest(res.template))
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: "Report template has been activated"}))
            handleClose();
        } catch (e: any) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: e.response.message.text}))
        }
    };

    return (
        <Modal open={open} handleClose={handleClose}>
            <Box
                sx={{
                    width: "360px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: ".75rem",
                    }}
                >
                    <Box />
                    <Typography sx={{ fontWeight: "500", pl: ".5rem" }}>Drafts</Typography>
                    <IconButton
                        sx={{
                            p: "0",
                            borderRadius: "0",
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon style={{ color: "var(--main-black-color)" }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        borderBottom: "2px solid var(--main-divider-color)",
                        p: "16.5px 42px 16.5px 12px",
                        mb: ".75rem",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "500",
                            width: "100%",
                            borderRight: "1px solid var(--main-divider-color)",
                        }}
                    >
                        Report name
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".25rem",
                    }}
                >
                    {reportsList && reportsList.length > 0 ? (
                        reportsList.map(report => (
                            <Box
                                key={report.id}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
                                }}
                            >
                                <Box
                                    sx={{
                                        pl: ".75rem",
                                        width: "100%",
                                        cursor: "pointer",
                                        height: "42px",
                                        display: "flex",
                                        alignItems: "center",
                                        "&:hover": {
                                            backgroundColor: "var(--main-purple-hover-color)",
                                        },
                                    }}
                                    onClick={() => chooseReportHandler(report.id)}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            verticalAlign: "middle",
                                            borderRight: "1px solid var(--main-divider-color)",
                                        }}
                                    >
                                        {report.name}
                                    </Typography>
                                </Box>
                                <IconButton
                                    sx={{
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "0",
                                    }}
                                    onClick={() => deleteReport(report.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                textAlign: "center",
                                color: "var(--main-purple-color)",
                            }}
                        >
                            There are no saved templates
                        </Typography>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default DraftReportsModal;
