import { Box, Menu, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ValueInputField from "../../../../UI/Inputs/ValueInputField";
import AddBadge from "../../../Badges/AddBadge";
import RemoveBadge from "../../../Badges/RemoveBadge";
import FilterPanelButton from "../FilterPanelButton";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import Badges from "../TableFilterPanelStatistics/components/Badges";
import {Dictionary} from "../../types";
import {generateId} from "../../../../services/helpers";
import {useGetStatisticsFiltersQuery} from "../../../../api/statisticsApi";
import Loading from "../../../Loading/Loading";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    addNewFilter,
    changeFiler,
    FiltersRequestDataType,
    removeFilter
} from "../../../../store/reducer/statisticsReducer";

interface IFilterItems {
    [key: string]: {
        column: string;
        options: string[];
        filters?: string[];
    };
}

interface IReportFilter {
    badges?: boolean;
}
const ReportFilter = ({badges = false }: IReportFilter) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch()
    const request = useAppSelector(state => state.StatisticsReducer)
    const {data: filterItems, isLoading} = useGetStatisticsFiltersQuery("")
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddFilter = () => {
        const newFilter: FiltersRequestDataType = {
            fieldId: generateId(),
            key: "",
            option: "",
            value: "",
        }
        dispatch(addNewFilter({newFilter}))
    };

    const handleRemoveFilter = (filter: Record<string, any>) => {
        console.log("handleRemoveFilter", filter)
        dispatch(removeFilter({filter}))
    };

    const handleChangeFilter = (fieldId: string, fieldKey: "fieldId" | "key" | "option" | "value", value: string) => {
        dispatch(changeFiler({fieldKey, fieldId, value}))
    };

    if(isLoading) return <Loading/>

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<FilterIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="Filters" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: "1.5rem",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    "& .MuiList-root": {
                        p: ".25rem 0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    },
                }}
            >
                {filterItems && Object.keys(filterItems).length > 0 &&
                    request.filters.length > 0 &&
                    request.filters.map((filter: FiltersRequestDataType, filterIndex: number) => (
                        <Box
                            key={filterIndex}
                            sx={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                                flexDirection: {xs: "column", md: "row"}
                            }}
                        >
                            <TextField
                                id="outlined-select-Columns"
                                select
                                label="Columns"
                                value={filter.key}
                                sx={{
                                    width: "312px",
                                }}
                                color="warning"
                                InputLabelProps={{
                                    color: "primary",
                                }}
                                InputProps={{
                                    sx: {
                                        borderRadius: "0",
                                    },
                                }}
                                onChange={e => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "key", "") : handleChangeFilter(filter.fieldId, "key", e.target.value))}
                            >
                                <MenuItem key="clear" value="clear">
                                    Clear selection
                                </MenuItem>
                                {Object.entries(filterItems).map((filterItem, index: number) => (
                                    <MenuItem key={index} value={filterItem[0]}>
                                        {filterItem[1].column}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {filter.key && (
                                <TextField
                                    id="outlined-select-Columns"
                                    select
                                    label="Select from"
                                    value={filter.option}
                                    sx={{
                                        width: "312px",
                                    }}
                                    color="warning"
                                    InputLabelProps={{
                                        color: "primary",
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "0",
                                        },
                                    }}
                                    onChange={e => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "option", "") : handleChangeFilter(filter.fieldId, "option", e.target.value))}
                                >
                                    <MenuItem key="clear" value="clear">
                                        Clear selection
                                    </MenuItem>
                                    {
                                        filterItems[filter.key].options.map((option: any, index: number) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            )}
                            {filter.key &&
                                filter.option &&
                                (filterItems[filter.key]?.filters ? (
                                    <TextField
                                        id="outlined-select-Columns"
                                        select
                                        label="Select from"
                                        value={filter.value}
                                        sx={{
                                            width: "312px",
                                        }}
                                        color="warning"
                                        InputLabelProps={{
                                            color: "primary",
                                        }}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "0",
                                            },
                                        }}
                                        onChange={e => (e.target.value === "clear" ? handleChangeFilter(filter.fieldId, "value", "") : handleChangeFilter(filter.fieldId, "value", e.target.value))}
                                    >
                                        <MenuItem key="clear" value="clear">
                                            Clear selection
                                        </MenuItem>
                                        {filterItems[filter.key]?.filters?.map((option: any, index: number) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <ValueInputField Icon={<SearchIcon />} label="Filter" value={filter.value} onChange={e => handleChangeFilter(filter.fieldId, "value", e.target.value)} />
                                ))}
                            {filterIndex === 0 ? (
                                <Box
                                    onClick={handleAddFilter}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <AddBadge />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => handleRemoveFilter(filter)}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <RemoveBadge />
                                </Box>
                            )}
                        </Box>
                    ))}
            </Menu>
            {badges && <Badges filterGroup={request.filters} removeBadge={handleRemoveFilter} keyField="fieldId" request={request} field="filters" mappingList={filterItems} />}
        </Box>
    );
};

export default ReportFilter;
