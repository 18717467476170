import {Box, Button, ButtonGroup} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DateView } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../UI/Inputs/DatePicker";
import { ReactComponent as PeriodIcon } from "../../../../assets/icons/periodIcon.svg";
import IconSelectInputFieldTransparent from "../../../../UI/Inputs/IconSelectInputFieldTransparent";
import { ReactComponent as MoneyIcon } from "../../../../assets/icons/moneyIcon.svg";
import {Dictionary} from "../../types";
import {handleChangeInnerField} from "../../../../services/helpers";
import {DayJSType} from "../../../../store/reducer/periodTableReducer";
import {useGetCurrenciesQuery} from "../../../../api/currenciesApi";

interface IPeriod {
    views?: DateView[] | null;
    request: Dictionary<any>;
    changePeriod: (periodsArg: {date_from?:DayJSType, date_to?: DayJSType, periodType?: string}) => void;
    isStatistics?: boolean;
}

const hotKeys = [{
    key: "today",
    label: "Today",
},{
    key: "yesterday",
    label: "Yesterday",
},{
    key: "week",
    label: "Last 7 Days",
}, {
    key: "last_month",
    label: "Last Month",
}, {
    key: "this_month",
    label: "This Month",
}]

const Period = ({ views = null, request, changePeriod, isStatistics = false }: IPeriod) => {
    const { periods } = request;
    const [activeHotKey, setActiveHotKey] = useState("");
    const {data: currencies} = useGetCurrenciesQuery("")

    useEffect(() => {
        if (request.periods.periodHotKey) {
            changePeriodHotKey(request.periods.periodHotKey);
        }
    }, [request.periods.periodHotKey]);

    const changePeriodHotKey = (key: string) => {
        let date_from
        let date_to
        switch (key) {
            case "today":{
                date_from = dayjs().format("YYYY-MM-DD");
                date_to = dayjs().format("YYYY-MM-DD");
                break;
            }
            case "yesterday":{
                date_from = dayjs().add(-1, "day").format("YYYY-MM-DD");
                date_to = dayjs().add(-1, "day").format("YYYY-MM-DD");
                break;
            }
            case "week":{
                date_from = dayjs().add(-6, "day").format("YYYY-MM-DD")
                date_to = dayjs().format("YYYY-MM-DD")
                break;
            }
            case "last_month":{
                date_from = dayjs().add(-1, "month").startOf("month").format("YYYY-MM-DD")
                date_to = dayjs().add(-1, "month").endOf("month").format("YYYY-MM-DD")
                break;
            }
            case "this_month":{
                date_from = dayjs().startOf("month").format("YYYY-MM-DD")
                date_to = dayjs().format("YYYY-MM-DD")
                break;
            }
            default:
                break;
        }
        changePeriod({date_from, date_to})
        setActiveHotKey(key);
    }

    return (
        <Box sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, gap: {xs: ".25rem", md: ".75rem"} }}>
            <Box
                sx={{
                    display: "flex",
                    gap: ".75rem",
                    alignItems: "center",
                    p: "6px 8px",
                    fontWeight: 500,
                }}
            >
                <PeriodIcon />
                Period
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: {xs: ".5rem", md: "1rem"},
                    alignItems: "center",
                    px: {xs: ".25rem", md: "0"},
                    color: "#00000066",
                }}
            >
                <ButtonGroup size="large" aria-label="Large button group" sx={{display: {xs: "none", md: "block"}}}>
                    {hotKeys.map(hotKey => (
                        <Button
                            disableRipple
                            onClick={() => changePeriodHotKey(hotKey.key)}
                            key={hotKey.key}
                            sx={{
                                flex: {xs: hotKey.key !== "today" ? "" : "", md: "auto"},
                                fontWeight: "400",
                                color: activeHotKey === hotKey.key ? "white" :"black", background: activeHotKey === hotKey.key ? "var(--blue) !important" : "white", px: {xs: ".25rem", md: ".75rem"},
                                height: {xs: "40px", md: "auto"}, textTransform: "none"}}
                        >
                            {hotKey.label}
                        </Button>
                    ))}
                </ButtonGroup>
                <Box sx={{display: {xs: "flex", md: "none"}, gap: ".25rem", flexWrap: "wrap", justifyContent: "center"}}>
                    {hotKeys.map(hotKey => (
                        <Button
                            disableRipple
                            onClick={() => changePeriodHotKey(hotKey.key)}
                            key={hotKey.key}
                            sx={{
                                width: "32%",
                                flex: {xs: hotKey.key !== "today" ? "" : "", md: "auto"},
                                fontWeight: "400",
                                color: activeHotKey === hotKey.key ? "white" :"black", background: activeHotKey === hotKey.key ? "var(--blue) !important" : "white", px: {xs: ".25rem", md: ".75rem"},
                                height: {xs: "40px", md: "auto"}, textTransform: "none"}}
                        >
                            {hotKey.label}
                        </Button>
                    ))}
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: ".25rem", alignItems: "center"}}>
                    <DatePickerCustom
                        {...(views ? { views } : {})}
                        value={periods.date_from}
                        onChange={newValue => {
                            changePeriod({date_from:newValue, periodType: "date_from"})
                            setActiveHotKey("");
                        }}
                        style={{ backgroundColor: "white", width: "150px" }}
                        disableBorders
                    />
                    <ArrowForwardIcon />
                    <DatePickerCustom
                        {...(views ? { views } : {})}
                        value={periods.date_to}
                        onChange={newValue => {
                            changePeriod({date_to:newValue, periodType:"date_to"})
                            setActiveHotKey("");
                        }}
                        style={{ backgroundColor: "white", width: "150px" }}
                        disableBorders
                    />
                </Box>
                {isStatistics ? (
                    <IconSelectInputFieldTransparent
                        value={request.periods.currency}
                        label="Currency2"
                        items={currencies ?? []}
                        defaultValue="USD"
                        onChange={(value: string) => handleChangeInnerField("periods", "currency", value, changePeriod)}
                        icon={<MoneyIcon style={{ marginRight: "6px" }} />}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default Period;
