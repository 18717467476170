import {Navigate, Outlet} from "react-router-dom";
import {FC} from "react";
import {PrivateRouteType} from "./type";
import {useAuthMeQuery} from "../api/authApi";
import Loading from "../components/Loading/Loading";

const PrivateRoute: FC<PrivateRouteType> = ({children= null}) => {
    const  {data: meData, error, isLoading} = useAuthMeQuery("")
    if (isLoading) {
        return <Loading />
    }

    if (!meData && !isLoading) {
        return <Navigate to="/sign-in" replace />;
    }

    return <>{children}</> || <Outlet />;
};

export default PrivateRoute;