import { Button } from "@mui/material";
import React, { ReactElement } from "react";

type FilterPanelButtonType = {
    active?: boolean;
    Icon: ReactElement;
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const FilterPanelButton = ({ active = false, Icon, onClick = () => {}, text = "" }: FilterPanelButtonType) => (
    <Button
        startIcon={Icon}
        sx={{
            p: "9px 12px",
            fontSize: "16px",
            textTransform: "none",
            color: "var(--main-black-color)",
            fontWeight: "500",
            backgroundColor: active ? "#fff" : "inherit",
            borderRadius: "0",
        }}
        onClick={onClick}
    >
        {text}
    </Button>
);

export default FilterPanelButton;
