import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import {RowsType} from "./updateTable"
import {categoriesApi} from "./categoriesApi";
import {adStylesApi} from "./adStylesApi";
import {channelsApi} from "./channelsApi";
import {relatedSearchesApi} from "./relatedSearchecsApi";

export type FetchResponseTableUsersListType ={
    headers: Array<{ key: string, label: string }>
    rows: RowsType[]
}

export type CategoryType = {
    name: string,
    site: string
}

export const tableActionApi = createApi({
    reducerPath: "tableActionApi",
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        actionTableApi: builder.mutation<any, {system: string, id: string, key: string}>({
            query: ({system, id, key}) => ({url: `/search/${system}/${id}/${key}/`, method:"GET"}),
            async onQueryStarted ({system,id, key}, {queryFulfilled, dispatch}){
                try {
                    await queryFulfilled
                    console.log("queryFulfilled done")
                    switch (system) {
                        case "ad_category": {
                            dispatch(categoriesApi.endpoints?.getCategoriesApiTable.initiate("", {forceRefetch: true}))
                            break
                        }
                        case "ad_styles": {
                            dispatch(adStylesApi.endpoints?.getAdStylesTable.initiate("", {forceRefetch: true}))
                            break
                        }
                        case "channels": {
                            dispatch(channelsApi.endpoints?.getChannelsTable.initiate("", {forceRefetch: true}))
                            break
                        }
                        case "related_searchs": {
                            console.log("tableActionApi")
                            dispatch(relatedSearchesApi.endpoints?.getRelatedSearchesTable.initiate("", {forceRefetch: true}))
                            break
                        }
                        default: break
                    }
                    console.log("after dispatch")
                }catch (e) {
                    console.log("tableActionApi Error!")
                }
            },
        }),
    })
})

export const {
    useActionTableApiMutation
} = tableActionApi
