import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";

export const afsSitesListApi = createApi({
    reducerPath: "afsSitesListAPI",
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        getAfsSiteList: builder.query<string[], string>({
            query: arg => ({url: "/search/sites/"})
        })
    })
})

export const {
    useGetAfsSiteListQuery
} = afsSitesListApi
