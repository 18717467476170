import React, {Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "../routes/PrivateRoute";
import Loading from "../components/Loading/Loading";
import Users from "../pages/buyer/Users/Users";
import User from "../pages/buyer/Users/User";
import AdStyles from "../pages/buyer/AdStyles/AdStyles";
import AdStyle from "../pages/buyer/AdStyles/AdStyle";
import Channels from "../pages/buyer/Channels/Channels";
import Channel from "../pages/buyer/Channels/Channel";
import Categories from "../pages/buyer/Categories/Categories";
import Category from "../pages/buyer/Categories/Category";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import Statistics from "../pages/buyer/Statistics";
import DashboardPage from "../pages/buyer/Dashboard";
import AlertNotification from "../components/AlertNotification/AlertNotification";
import NotFound from "../pages/buyer/NotFound/NotFound";
import Forbidden from "../pages/buyer/Forbidden/Forbidden";

const MainLayout = React.lazy(() => import("../layouts/BuyerMainLayout"));
const DashboardTest = React.lazy(() => import("../pages/buyer/RelatedSearchs/RelatedSearches"));
const SignIn = React.lazy(() => import("../pages/buyer/SignIn/SignIn"));
const RelatedSearchTest = React.lazy(() => import("../pages/buyer/RelatedSearchs/RelatedSearch"));


const App = () =>
    (
        <Suspense fallback={<Loading/>}>
            <AlertNotification/>
            <Routes>
                <Route path="/" element={
                    <PrivateRoute>
                        <MainLayout/>
                    </PrivateRoute>
                }>
                    <Route index element={<DashboardPage/>}/>
                    <Route path="/related_searchs" element={<DashboardTest/>}/>
                    <Route path="/related_searchs/create-related-search" element={<RelatedSearchTest mode="create"/>}/>
                    <Route path="/related_searchs/view/:id" element={<RelatedSearchTest mode="view"/>}/>
                    <Route path="/related_searchs/edit/:id" element={<RelatedSearchTest mode="edit"/>}/>
                    {/*______________________*/}
                    <Route element={<ProtectedRoute permission="user_view"/>}>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/users/create" element={<User mode="create"/>}/>
                        <Route path="/users/view/:id" element={<User mode="view"/>}/>
                        <Route path="/users/edit/:id" element={<User mode="edit"/>}/>
                    </Route>
                    <Route element={<ProtectedRoute permission="style_view"/>}>
                        <Route path="/ad_styles" element={<AdStyles/>}/>
                        <Route path="/ad_styles/create" element={<AdStyle mode="create"/>}/>
                        <Route path="/ad_styles/view/:id" element={<AdStyle mode="view"/>}/>
                        <Route path="/ad_styles/edit/:id" element={<AdStyle mode="edit"/>}/>
                    </Route>
                    <Route element={<ProtectedRoute permission="channel_view"/>}>
                        <Route path="/channels" element={<Channels/>}/>
                        <Route path="/channels/create" element={<Channel mode="create"/>}/>
                        <Route path="/channels/view/:id" element={<Channel mode="view"/>}/>
                        <Route path="/channels/edit/:id" element={<Channel mode="edit"/>}/>
                    </Route>
                    <Route element={<ProtectedRoute permission="category_view"/>}>
                        <Route path="/ad_category" element={<Categories/>}/>
                        <Route path="/ad_category/create" element={<Category mode="create"/>}/>
                        <Route path="/ad_category/view/:id" element={<Category mode="view"/>}/>
                        <Route path="/ad_category/edit/:id" element={<Category mode="edit"/>}/>
                    </Route>
                    <Route path="/statistics" element={<Statistics/>}/>
                </Route>
                <Route path="/*" element={<NotFound/>}/>
                <Route path="/sign-in" element={<SignIn/>}/>
                <Route path="/permission-denied" element={<Forbidden/>}/>
            </Routes>
        </Suspense>
    )


export default App;
