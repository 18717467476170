import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type StateAlertType = {
    nameTemplateStatistics: string
}

const initialState: StateAlertType  = {
    nameTemplateStatistics: ""
}

const templateStatisticsSlice = createSlice({
    name: "templateStatisticsSlice",
    initialState,
    reducers: {
        changeNameStatisticsTemplate: (state, action: PayloadAction<{nameTemplateStatistics: string}>) => {
            state.nameTemplateStatistics = action.payload.nameTemplateStatistics
        }
    }
})

export const { changeNameStatisticsTemplate } = templateStatisticsSlice.actions

export default templateStatisticsSlice.reducer