import {Box, Checkbox, FormControlLabel, FormGroup, Menu, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FilterPanelButton from "../FilterPanelButton";
import {ReactComponent as CopyIcon} from "../../../../assets/icons/copyIcon.svg";
import {ReactComponent as WarningIcon} from "../../../../assets/icons/warning.svg";
import Badges from "../TableFilterPanelStatistics/components/Badges";
import { IMetric} from "../../../../pages/buyer/Statistics";
import {StatisticsGroupAndMetricsDataRequestType, useGetStatisticsGroupsQuery} from "../../../../api/statisticsApi";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {chooseFieldInGroupsOrMetrics} from "../../../../store/reducer/statisticsReducer";
import Loading from "../../../Loading/Loading";

interface IGrouping {
    badges?: boolean;
}
const Grouping = ({badges = false }: IGrouping) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch()
    const request = useAppSelector(state => state.StatisticsReducer)
    const {data: groupList, isLoading} = useGetStatisticsGroupsQuery({groups:request.groups, metrics: request.metrics})

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChooseParam = async (newField: Record<string, any>) => {
        const data = {fieldName: "groups", newField} as {fieldName: "groups", newField: StatisticsGroupAndMetricsDataRequestType}
        dispatch(chooseFieldInGroupsOrMetrics(data))
    };

    if(isLoading) <Loading/>

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<CopyIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="Grouping" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: ".75rem",
                        width: "min-content",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    ".MuiMenu-list": {
                        p: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    },
                }}
            >
                <FormGroup
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, auto)",
                        gap: "0.75rem",
                        boxSizing: "border-box",
                    }}
                >
                    {groupList && groupList.length > 0 &&
                        groupList.map((group, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {group.label}
                                </Typography>
                                {group.keys.length > 0 &&
                                    group.keys.map((item, index) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={request.groups.findIndex((activeGroup: IMetric) => activeGroup.key === item.key) !== -1}
                                                        disabled={!item.is_enabled}
                                                        onChange={() => handleChooseParam(item)}
                                                    />
                                                }
                                                label={`${item.name}`}
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {!item.is_enabled && (
                                                    <Tooltip title="Can't select together">
                                                        <WarningIcon />
                                                    </Tooltip>
                                                )}
                                                <Tooltip title={item.hint || ""}>
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                        ))}
                </FormGroup>
            </Menu>
            {badges && <Badges filterGroup={request.groups} removeBadge={handleChooseParam} keyField="key" request={request} field="groups" />}
        </Box>
    );
};

export default Grouping;
