import {useNavigate, useParams} from "react-router-dom";
import {FC} from "react";
import {CategoryType, UserProps} from "./type";
import CategoryForm from "../../../components/Forms/CategoryForm/CategoryForm";
import {useAppDispatch} from "../../../hooks/redux";
import {setAlertData} from "../../../store/reducer/alertReducer";
import {useCreateCategoryMutation, useEditCategoryMutation, useGetCategoryQuery} from "../../../api/categoriesApi";

const Category: FC<UserProps> = ({ mode="create" }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const {data: user= {site: "", name: ""}, } = useGetCategoryQuery(id, {skip: !id})
    const [createCategory] = useCreateCategoryMutation()
    const [editCategory] = useEditCategoryMutation()

    const onSubmit = async (data: CategoryType) => {
        try {
            if (mode === "create") {
                await  createCategory(data).unwrap()
            }else{
                await  editCategory({data, idCategory: id !}).unwrap()
            }
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: `Category was successfully ${mode === "create" ? "created" : "edited"}`}))
            navigate("/ad_category");
        } catch (err) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: `Error occurred while ${mode === "create" ? "creating" : "editing"} category`}))
            console.log("Error creating category");
        }
    }

    const formData = mode !== "create" && Object.keys(user).length > 0
        ? user as CategoryType
        : undefined


    return (
        <CategoryForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default Category;