import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import updateDashboardOrStatisticsTable, {
    IMetric,
    ResponseDashboardOrStatisticsDataTableType
} from "./updateDashboardOrStatisticsTable";

export type FetchResponseDashboardOrStatisticsTableType ={
    group?: IMetric
    groups?: Record<string, any>[]
    headers: Record<string, any>[]
    metrics?: Record<string, any>[]
    rows: Record<string, any>[]
    total?: Record<string, any>
}

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        // getDashboardTable: builder.query<ResponseDashboardOrStatisticsDataTableType, Record<string, any>>({
        getDashboardTable: builder.query<ResponseDashboardOrStatisticsDataTableType<"withGroup">, Record<string, any>>({
            query: body => ({url: "/report/main/", method: "POST", body}),
            transformResponse: (response: FetchResponseDashboardOrStatisticsTableType) => updateDashboardOrStatisticsTable<"withGroup">({responseTableData: response}),
        })
    })
})

export const {useGetDashboardTableQuery} = dashboardApi
