import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../services/constants";
import updateTable, {ResponseDataTableType, RowsType} from "./updateTable"
import {StyleResponseType} from "./adStylesApi";

export type FetchResponseTableUsersListType ={
    headers: Array<{ key: string, label: string }>
    rows: RowsType[]
}

export type ChannelResponseType = {
    channel_name: string,
    channel: string,
    username: Array<string> | string,
}

export const channelsApi = createApi({
    reducerPath: "channelsApi",
    tagTypes: ["mutationChanel"],
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL, credentials: "include"}),
    endpoints: builder => ({
        getChannelsTable: builder.query<ResponseDataTableType, string>({
            query: arg => ({url: "/search/channels/list/"}),
            providesTags: ["mutationChanel"],
            transformResponse: (responseTableData: FetchResponseTableUsersListType) => updateTable({responseTableData}),
        }),
        getChanel: builder.query<ChannelResponseType, string | undefined>({
            query: id => ({url: `/search/channels/${id}/details/`}),
            providesTags: ["mutationChanel"]
        }),
        creatChanel: builder.mutation<{message: string}, ChannelResponseType>({
            query: body => ({url: "/search/channels/create/", method: "POST", body}),
            invalidatesTags: ["mutationChanel"]
        }),
        editChanel: builder.mutation<{message: string}, {data: ChannelResponseType, idChanel: string}>({
            query: ({data, idChanel}) => ({url: `/search/channels/${idChanel}/edit/`, method: "POST", body: data}),
            invalidatesTags: ["mutationChanel"]
        }),
    })
})

export const {
    useGetChannelsTableQuery,
    useCreatChanelMutation,
    useEditChanelMutation,
    useGetChanelQuery
} = channelsApi
