import {Box, Button, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {Controller} from "react-hook-form";
import {FC} from "react";
import Divider from "@mui/material/Divider";
import InputTest from "./Input";

const FormInputs: FC<any> = ({ field={}, name, control, formValues = [], error = {}, readOnly=false, isNotView = true }) => {
    const {type, defaultValue, rules, order = "initial", title, hint, big_title, isDivider = false} = field;

    let isBreak = false;
    if (Object.hasOwn(field, "dependencies")) {
        field.dependencies.forEach((dependency: any) => {
            if ((formValues as any)[dependency.fieldKey] && dependency.value !== (formValues as any)[dependency.fieldKey]) {
                isBreak = true;
            }
        })
    }
    if (isBreak) {
        return null;
    }
    if (type === "array") {
        const {fieldsValues: arrFields, append, remove, requiredElementsCount} = field;
        return (
            <Box component="section" sx={{order, display: "flex", gap: "24px", flexDirection: "column", width: "100%"}}>
                {
                    isDivider ? <Divider/> : null
                }
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Typography sx={{fontWeight: "500", fontSize: "20px", lineHeight: "24px", color: "#0075EB"}}>{title}:</Typography>

                    {
                        !readOnly && append &&
                        <Button onClick={append} color="primary" size="large" variant="contained" sx={{borderStyle: "dashed"}}>
                            +  Добавить элемент
                        </Button>
                    }
                </Box>

                <Box sx={{display: "flex", flexWrap: "wrap", gap: "24px"}}>
                    {
                        arrFields.map((item: any, index: number) => {
                            console.log()
                            return (
                                <Box sx={{minWidth: "calc(50% - 24px)", display: "flex", flexDirection: "column", gap: "16px"}}>
                                    {
                                        remove &&
                                        <Box key={index} sx={{display: "flex", justifyContent: "space-between", alignItems: "center",}}>
                                            <Typography sx={{fontWeight: "500", fontSize: "16px", lineHeight: "24px", color: "#000"}}>Ключевое слово  {index + 1}</Typography>
                                            {
                                                isNotView &&
                                                <IconButton
                                                    disabled={index < requiredElementsCount}
                                                    aria-label="delete" size="small"
                                                    onClick={() => remove(index)}
                                                >
                                                    <DeleteIcon sx={{color: "rgba(0,0,0,0.56)"}} fontSize="small"/>
                                                </IconButton>
                                            }
                                        </Box>
                                    }

                                    {
                                        Object.keys(item).map((key: string) => {
                                            if (key === "id") return null;
                                            const {title, hint, rules, defaultValue} = field.fieldsSchema[key];
                                            return (
                                                <>
                                                    <Box key={`${name}.${index}.${key}`} sx={{
                                                        display: "flex",
                                                        alignItems: "self-end",
                                                        gap: ".5rem",
                                                        flexDirection: "column",
                                                    }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`${name}.${index}.${key}` as any}
                                                            control={control}
                                                            rules={type !== "checkbox" ? rules : {}}
                                                            defaultValue={defaultValue}
                                                            render={({field: {onChange, value}}) =>(
                                                                <InputTest
                                                                    readOnly={readOnly}
                                                                    errors={error}
                                                                    label={title}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    {...field.fieldsSchema[key]}
                                                                    hint={hint}
                                                                />)
                                                            }
                                                        />
                                                    </Box>
                                                </>
                                            )
                                        })
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>

            </Box>
        )
    }


    return (
        <section
            key={name}
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                width: "100%",
                height: "fit-content",
                order
            }}
        >
            {
                isDivider ? <Divider/> : null
            }
            {
                big_title &&
                <Typography sx={{fontWeight: "500",}}>{big_title}:</Typography>
            }
            <Controller
                name={name as any}
                control={control}
                rules={type !== "checkbox" ? rules : {}}
                defaultValue={defaultValue}
                render={({field: inputField}) =>
                    <InputTest
                        readOnly={readOnly}
                        errors={error}
                        label={title}
                        value={inputField.value}
                        onChange={inputField.onChange}
                        {...field}
                        hint={hint}
                    />
                }
            />
        </section>
    );
}

export default FormInputs;