import {useNavigate, useParams} from "react-router-dom";
import {FC} from "react";
import {UserProps, UserType} from "./type";
import UserForm from "../../../components/Forms/UserForm/UserForm";
import {useAppDispatch} from "../../../hooks/redux";
import {setAlertData} from "../../../store/reducer/alertReducer";
import {useCreatUserMutation, useEditUserMutation, useGetUserQuery} from "../../../api/usersApi";

const initialUserData = {
    first_name: "",
    last_name: "",
    username: "",
    channels: [],
    ad_styles: [],
    user_view: false,
    channel_view: false,
    style_view: false,
    category_view: false,
}

const User: FC<UserProps> = ({ mode="create" }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const {data: user = initialUserData } = useGetUserQuery(id, {skip: !id})
    const [editUser] = useEditUserMutation()
    const [createUser] = useCreatUserMutation()

    const onSubmit = async (data: UserType) => {
        try {
            if (mode === "create") {
                await createUser(data).unwrap()
            }else {
                await editUser({data, userId: id !}).unwrap()
            }
            dispatch(setAlertData({openAlert: true, typeAlert: "success", messageAlert: `User was successfully ${mode === "create" ? "created" : "edited"}`}))
            navigate("/users");
        } catch (err) {
            dispatch(setAlertData({openAlert: true, typeAlert: "error", messageAlert: `Error occurred while ${mode === "create" ? "creating" : "editing"} user`}))
            console.log("Error creating related search");
        }
    }

    const formData = mode !== "create" && Object.keys(user).length > 0
        ? user as UserType
        : undefined

    return (
        <>
            <UserForm mode={mode} formData={formData} onSubmit={onSubmit} />
        </>
    )
}

export default User;