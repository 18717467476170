import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/global.css";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/store";
import App from "./app/App";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);



root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
